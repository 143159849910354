<template>
  <div>
    <CRow>
      <CCol sm="12">
        <CCard>
          <CCardHeader>
            <strong>Data Peserta </strong><small>Tambah Data</small>
          </CCardHeader>
          <CCardBody>
            <CForm @submit.prevent="inputPeserta">
            <CRow>
              <CCol sm="6">
                <CInput
                  v-model="nik_pegawai"
                  label="NIK Pegawai"
                  placeholder="Input NIK Pegawai"
                />
              </CCol>
              <CCol sm="6">
                <CInput
                  v-model="nip_pegawai"
                  label="NIP Pegawai"
                  placeholder="Input NIP Pegawai"
                />
              </CCol>
              <CCol sm="6">
                <CInput
                  v-model="nama_pegawai"
                  label="Nama Lengkap"
                  placeholder="Input Nama Lengkap"
                />
              </CCol>
              <CCol sm="3">
                <CInput
                  v-model="tempat_lahir_pegawai"
                  label="Tempat Lahir Pegawai"
                  placeholder="Input Tempat Lahir Pegawai"
                />
              </CCol>
              <CCol sm="3">
                <CInput
                  v-model="tgl_lahir_pegawai"
                  type="date"
                  label="Tanggal Lahir Pegawai"
                  placeholder="Input Tanggal Lahir Pegawai"
                />
              </CCol>
              <CCol sm="3">
                <div class="form-group">
                <label>Jenis Kelamin</label>
                  <select v-model="jenis_kelamin_pegawai" class="form-control">
                    <option value="" disabled selected>Pilih Jenis Kelamin</option>
                    <option value="L">Laki-laki</option>
                    <option value="P">Perempuan</option>
                  </select>
                </div>
              </CCol>
              <CCol sm="3">
                <div class="form-group">
                <label>Agama</label>
                  <select v-model="agama_pegawai" class="form-control">
                    <option value="" disabled selected>Pilihan</option>
                    <option value="1">Islam</option>
                    <option value="2">Kristen Protestan</option>
                    <option value="3">Kristen Katolik</option>
                    <option value="4">Hindu</option>
                    <option value="5">Budha</option>
                  </select>
                </div>
              </CCol>
              <CCol sm="3">
                <div class="form-group">
                <label>Status Perkawinan</label>
                  <select v-model="status_nikah_pegawai" class="form-control">
                    <option value="" disabled selected>Pilihan</option>
                    <option value="1">Belum Kawin</option>
                    <option value="2">Sudah Kawin</option>
                    <option value="3">Janda</option>
                    <option value="4">Duda</option>
                  </select>
                </div>
              </CCol> 
              <CCol sm="3">
                <CInput
                  v-model="telpon_pegawai"
                  type="phone"
                  label="Nomor Telepon Pegawai"
                  placeholder="Input Nomor Telepon Pegawai"
                />
              </CCol>
              <CCol sm="6">
                <CInput
                  v-model="jabatan_pegawai"
                  label="Jabatan Pegawai"
                  placeholder="Input Jabatan Pegawai"
                />
              </CCol>
              <CCol sm="6">
                <CInput
                  v-model="pangkat_pegawai"
                  label="Pangkat Pegawai"
                  placeholder="Input Pangkat Pegawai"
                />
              </CCol>
              <CCol sm="12">
                <CInput
                  v-model="alamat_pegawai"
                  label="Alamat Pegawai"
                  placeholder="Input Alamat Pegawai"
                />
              </CCol>          
            </CRow>          
              <div class="form-actions float-right">
                <CButton type="submit" color="primary" style='margin-right:15px'>Simpan</CButton>
                <CButton color="secondary" to="/peserta">Batal</CButton>
              </div>
            </CForm>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <CModal
      title="Error Input"
      color="danger"
      id="modalError"
    >
      <b>Error</b>! Data gagal diinput.
    </CModal>
   
  </div>
</template>
<script>
import InputTag from 'vue-input-tag'
import axios from 'axios';
import * as Modals from '../notifications/Modals.vue'
// import { CurrencyInput } from 'vue-currency-input'

export default {
  // components: {CurrencyInput },
  name: 'Forms',
  components: { 
    InputTag ,
  },
  data () {
    return {
      nik_pegawai : "",
      nip_pegawai : "",
      nama_pegawai : "",
      alamat_pegawai : "",
      jabatan_pegawai : "",
      tgl_lahir_pegawai : "",
      tempat_lahir_pegawai : "",
      jenis_kelamin_pegawai : "",
      pangkat_pegawai : "",
      agama_pegawai : "",
      telpon_pegawai : "",
      status_nikah_pegawai : "",
      selected: [], // Must be an array reference!
      // pesertas: [],
      show: true,
    }
  },
  mounted(){
  // this.loadData();
  },
  
  methods: {

    // loadData: function(event){
      
    //   let username =window.btoa(this.$route.params.id_user);
    //   console.log(username);

    //   axios.get(process.env.VUE_APP_BASE_URL+"pegawais")
    //     .then((response) => {
    //           // this.pesertas=response.data;
    //           const datas = response.data;
              
    //           this.pesertas = datas.map(d=>{return d['nama_pegawai']});;
    //           console.log("datas::"+this.pesertas)
    //     });
    // },

    inputPeserta: function(event){

      const formData = new FormData();
        
        formData.append('nik_pegawai', this.nik_pegawai)
        formData.append('nip_pegawai', this.nip_pegawai)
        formData.append('nama_pegawai', this.nama_pegawai)
        formData.append('alamat_pegawai', this.alamat_pegawai)
        formData.append('jenis_kelamin_pegawai', this.jenis_kelamin_pegawai)
        formData.append('agama_pegawai', this.agama_pegawai)
        formData.append('status_nikah_pegawai', this.status_nikah_pegawai)
        formData.append('jabatan_pegawai', this.jabatan_pegawai)
        formData.append('pangkat_pegawai', this.pangkat_pegawai)
        formData.append('tempat_lahir_pegawai', this.tempat_lahir_pegawai)
        formData.append('telpon_pegawai', this.telpon_pegawai)
        formData.append('tgl_lahir_pegawai', this.tgl_lahir_pegawai)
        // formData.append('jenis_alamat_pegawai', "1")
      
      // alert(JSON.stringify(anggota));
      axios.post(process.env.VUE_APP_BASE_URL+"pegawai", formData)
        .then((response) => {
            // console.log(response);
            // alert(response.data);
            if(response.status==200){
              this.$router.push('/peserta');
            }
            else{
              this.modalError = true;
            }            
        })
    }
  }
}
</script>